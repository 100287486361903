
import { Options, Vue } from 'vue-class-component';
import { Layout, Menu } from 'ant-design-vue';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  SlidersOutlined,
  FormOutlined,
  ProfileOutlined,
} from '@ant-design/icons-vue';
@Options({
  components: {
    'a-layout': Layout,
    'a-layout-header': Layout.Header,
    'a-layout-content': Layout.Content,
    'a-layout-sider': Layout.Sider,
    'menu-fold-outlined': MenuFoldOutlined,
    'menu-unfold-outlined': MenuUnfoldOutlined,
    'DashboardOutlined': DashboardOutlined,
    'FormOutlined': FormOutlined,
    'a-menu': Menu,
    'ProfileOutlined': ProfileOutlined,
    'SlidersOutlined': SlidersOutlined,
  },
})
export default class Public extends Vue {
  public collapsed = true;
  public mounted() {
    console.log(process.env);
  }
}
