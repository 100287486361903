<template>
  <div class="full">
    <a-layout id="components-layout-demo-custom-trigger" class="full">
      <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
        <div class="logo" />
        <a-menu
          theme="dark"
          mode="inline"
        >
          <router-link
            class="ant-menu-item"
            active-class="ant-menu-item-selected"
            to="/public/vehicle"
          >
            <DashboardOutlined />
            <span>车辆列表</span>
          </router-link>
          <router-link
            class="ant-menu-item"
            active-class="ant-menu-item-selected"
            to="/public/mission"
          >
            <FormOutlined />
            <span>任务列表</span>
          </router-link>
          <router-link
            class="ant-menu-item"
            active-class="ant-menu-item-selected"
            to="/public/formwork"
          >
            <ProfileOutlined />
            <span>模版列表</span>
          </router-link>
          <router-link
            class="ant-menu-item"
            active-class="ant-menu-item-selected"
            to="/public/presupposition"
          >
            <SlidersOutlined />
            <span>预设管理</span>
          </router-link>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0;box-shadow: #5f5a5a3b 0px 1px 1px 2px;z-index:10">
          <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
        </a-layout-header>
        <a-layout-content
          :style="{
            margin: '0px',
            padding: '0px',
            minHeight: '280px',
            height: '100%',
            overflow: 'hidden',
          }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- public
    <a-range-picker v-model:value="value3" @change="onChange" /> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Layout, Menu } from 'ant-design-vue';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  SlidersOutlined,
  FormOutlined,
  ProfileOutlined,
} from '@ant-design/icons-vue';
@Options({
  components: {
    'a-layout': Layout,
    'a-layout-header': Layout.Header,
    'a-layout-content': Layout.Content,
    'a-layout-sider': Layout.Sider,
    'menu-fold-outlined': MenuFoldOutlined,
    'menu-unfold-outlined': MenuUnfoldOutlined,
    'DashboardOutlined': DashboardOutlined,
    'FormOutlined': FormOutlined,
    'a-menu': Menu,
    'ProfileOutlined': ProfileOutlined,
    'SlidersOutlined': SlidersOutlined,
  },
})
export default class Public extends Vue {
  public collapsed = true;
  public mounted() {
    console.log(process.env);
  }
}
</script>
<style lang="less">
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-menu a.ant-menu-item {
  padding: 0px 25px;
}
</style>